import { ExpertiseSectionMock } from '~/types/mock'

const CloudAppDevBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'For startups',
    description:
      'We offer efficient cloud based application development services that enable you to launch your product quickly and test ideas within budget. At Codica, startup founders can get an app prototype, a new MVP, or improve their existing project.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'For small & mid-scale businesses',
    description:
      'Regarding your existing solution, our programming specialists can help you move to the cloud and reduce hardware costs. Moreover, we provide maintenance, scalable, and integration services.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'For enterprises',
    description:
      'Our team can transform your business according to the new market challenges and your company’s needs. Regarding the advantages of cloud solutions for enterprises, you can automate business processes and reduce costs.',
  },
]

export default CloudAppDevBusinesses
