import { FAQ } from '~/types/data-array'

const CloudAppDevFAQ: FAQ[] = [
  {
    question: 'What is cloud app development?',
    answer:
      'Cloud app development means creating, deploying, and managing applications on cloud-based infrastructure. The right development process leverages cloud resources and services for scalability, flexibility, and cost-effectiveness.',
  },
  {
    question: 'How much does it cost to develop a cloud-based app?',
    answer: `The cost of developing a cloud-based app can vary widely depending on the complexity of the app, the platform and tools used, and the development team's location and rates. However, a rough estimate starts from $50,000. The fully-fledged [SaaS app cost](/blog/cost-to-build-saas-app/) can reach $700,000 and beyond.`,
  },
  {
    question: 'How long does it take to make a cloud-based app?',
    answer:
      'The timeline for developing a cloud-based app varies depending on factors like app complexity, team size and experience, tools and platforms used, and development methodology. So, creating SaaS MVP takes 4-6 months, while implementing additional features can take from 8 months to 2 years or more.',
  },
  {
    question: 'What are the types of cloud service development?',
    answer:
      'Cloud service development has three types. Software as a service (SaaS) provides access to software apps over the Internet. Platform as a service (PaaS) allows developers to build, deploy, and manage apps. Infrastructure as a service (IaaS) provides computing resources over the Internet.',
  },
]

export default CloudAppDevFAQ
