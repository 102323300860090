import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const CloudAppDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: (
      <>
        <Link to="/services/product-discovery/" key="keyTitleProductDiscovery">
          Product discovery
        </Link>
      </>
    ),
    descr: (
      <>
        <Link
          to="/blog/saas-paas-iass-choosing-the-best-cloud-computing-model/"
          className="mr5"
          key="keyDescriptionProductDiscovery"
        >
          IaaS, PaaS, or SaaS
        </Link>
        - we pick cloud platform types per clients’ business needs. Designers,
        developers, and analysts create a product roadmap with defined
        development stages, budget, and timeline.
      </>
    ),
  },
  {
    num: '02',
    title: (
      <>
        <Link to="/services/ui-ux-design/" key="keyTitleDesign">
          UI/UX design
        </Link>
      </>
    ),
    descr:
      'Our designers perform deep market research and satisfy the user’s requirements for creating a user-friendly and attractive design. Also, they advise testing the UI design before coding.',
  },
  {
    num: '03',
    title: 'Product development',
    descr:
      'The coding phase begins by using cloud development methodologies and processes. Our effective planning and rapid response to challenges ensure successfully developed projects.',
  },
  {
    num: '04',
    title: 'Testing',
    descr:
      'Our QA experts create testing strategies for the product and define the acceptance criteria. Then, they test the quality using test cases and checklists and perform regression testing.',
  },
  {
    num: '05',
    title: 'Maintenance and support',
    descr:
      'Codica’s expert team monitors cloud-based apps vigilantly, responding promptly to any problems. We suggest reviewing and updating these apps regularly to stay competitive.',
  },
]

export default CloudAppDevProcess
