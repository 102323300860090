import { DataTabList } from '~/types/data-array'

const CloudAppDevTabList: DataTabList[] = [
  {
    name: 'Bakery platform',
  },
  {
    name: 'Rental solution',
  },
]

export default CloudAppDevTabList
