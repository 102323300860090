import { Link } from 'gatsby'

import { ExpertiseSectionMock } from '~/types/mock'

const CloudAppDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Consulting',
    description:
      'We offer consulting and strategic guidance on leveraging cloud technologies to optimize business operations, reduce costs, and improve the scalability and performance of apps.',
  },
  {
    icon: 'sprite3DIcon_17',
    title: 'Cloud infrastructure management',
    description:
      'Our cloud-based app development infrastructure management services involve setting up and managing the infrastructure required to develop and deploy cloud-based applications.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'Cloud application design',
    description:
      'We always create attractive user interfaces (UI) and best user experiences (UX) for cloud-based applications to enhance the usability and aesthetics of the products.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'Cloud app development',
    description: (
      <>
        Codica has expertise in
        <Link
          to="/blog/cloud-application-development/"
          className="mx5"
          key="keyLinkCloudAppDev"
        >
          cloud application development
        </Link>
        of native apps optimized for platforms such as Amazon Web Services
        (AWS), Sentry, Dropbox, Hubspot, and other cloud providers.
      </>
    ),
  },
  {
    icon: 'sprite3DIcon_18',
    title: 'Cloud migration',
    description:
      'Our specialists assist businesses in migrating their existing solutions or data to the cloud by re-architecting applications to be cloud-compatible.',
  },
  {
    icon: 'sprite3DIcon_16',
    title: 'Cloud integration',
    description:
      'We offer our clients to integrate cloud application services into their existing applications with cloud-based databases, storage, authentication, and other services.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Cloud application testing',
    description: (
      <>
        <Link
          to="/services/quality-assurance/"
          className="mr5"
          key="keyLinkQAServices"
        >
          QA testing services
        </Link>
        may include unit testing, integration testing, performance testing, and
        security testing to ensure the quality of the application development in
        cloud.
      </>
    ),
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Support',
    description:
      'Our cloud app development support services involve providing ongoing maintenance, monitoring, and troubleshooting for cloud-based applications.',
  },
]

export default CloudAppDevServices
