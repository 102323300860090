// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "M_hn";
export var cloudAppDevBusinessSection = "M_hh";
export var cloudAppDevCasesSection = "M_hp";
export var cloudAppDevIndustriesSection = "M_hj";
export var cloudAppDevModelSection = "M_hg";
export var cloudAppDevOurAchievementsSection = "M_hm";
export var cloudAppDevPrimeSection = "M_hd";
export var cloudAppDevProcessSection = "M_hk";
export var cloudAppDevQuotesSection = "M_hq";
export var cloudAppDevServicesSection = "M_hf";
export var cloudAppDevTechStackSection = "M_hl";