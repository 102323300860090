import { ExpertiseSectionMock } from '~/types/mock'

const CloudAppDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_101',
    title: 'Scalable and portable',
    description:
      'We split applications into services or microservices and containerize them to reduce downtime significantly.',
  },
  {
    icon: 'spriteIcon_73',
    title: 'Modifiable and compatible',
    description:
      'We employ a layered architecture and standardized APIs to make integration and updates seamlessly.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Resilient',
    description:
      'We use hierarchies of services and automated recovery that prevent application failure escalation.',
  },
  {
    icon: 'spriteIcon_37',
    title: 'Secure',
    description:
      'We prioritize integrated security throughout the development lifecycle with strong encryption, SSL, and monitoring.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Cost-efficient',
    description:
      'We carefully choose appropriate cloud-based services to minimize development efforts, timelines, and costs.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Fast',
    description:
      'We encourage our developers to upgrade their skills for faster problem-solving and the creation of efficient solutions.',
  },
]

export default CloudAppDevBenefits
