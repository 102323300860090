import { getImage, StaticImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { WorksTabsContent } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'
import useCloudAppDevelopmentStaticQuery from '~/views/Services/CloudAppDevelopment/useCloudAppDevelopmentStaticQuery'

const photoClientCaker =
  '../../../assets/images/avatars/clients/client-caker.png'
const quoteCodicaPhotoSergey = '../../../assets/images/avatars/Sergey.png'

const imageProps: CommonImageProps = {
  width: 50,
  height: 50,
}

const CloudAppDevTabContent = (): WorksTabsContent[] => {
  const query = useCloudAppDevelopmentStaticQuery()

  const portfolioCaker = getImage(fileToImageLikeData(query.portfolioCaker))
  const portfolioVaria = getImage(fileToImageLikeData(query.portfolioVaria))

  const tabContent = [
    {
      key: 'Bakery platform',
      gradient: 'gradientCaker',
      image: portfolioCaker,
      header: 'SaaS platform for CakerHQ',
      title:
        'CakerHQ is an Australian website for bakery businesses where cake makers sell flavorful desserts, and customers can book the confectionery easily and quickly.',
      resultsLeft: 'Delivered a reliable SaaS solution from scratch',
      resultsRight: 'Made easy and secure ordering/paying process',
      link: 'saas-platform-for-bakery-business',
      review:
        '“Nowadays, CakerHQ is live and operational in Australia and is helping both cake business owners with their administration and their clients with having a simple way of searching and booking their custom bakes. We have plans to expand CakerHQ’s features and offerings in the future, and I look forward to bringing these to life with the Codica team.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientCaker}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Lisa',
      clientPosition: 'Founder of CakerHQ',
    },
    {
      key: 'Rental solution',
      gradient: 'gradientVaria',
      image: portfolioVaria,
      header: 'Real estate portal',
      title:
        'The SaaS allows for showing real estate listings with 3D virtual tours. The real estate portal lets users see an apartment on the screen as if they are already there. You can post a listing and change details if needed on the platform.',
      resultsLeft:
        'Developed a SaaS solution that connects homeowners, agents, and tenants.',
      resultsRight: 'Provided property details management and 3D tours.',
      link: 'saas-real-estate-platform',
      review: [
        '“The task was to create a fast-loading and user-friendly SaaS for real estate. The SaaS needed to include features allowing users to view the real estate property and communicate with the homeowners.',
        <div className="mb-2" key="descrTwo" />,
        'Codica carefully approached the SaaS platform development and implemented the needed functionality. We put maximum effort and technical knowledge into achieving the goals set in the specification.”',
      ],
      get clientPhoto() {
        return (
          <StaticImage
            src={quoteCodicaPhotoSergey}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Serhii',
      clientPosition: 'Ruby on Rails Developer at Codica',
    },
  ]

  return tabContent
}

export default CloudAppDevTabContent
