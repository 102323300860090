import { ExpertiseSectionMock } from '~/types/mock'

const CloudAppDevModels: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_89',
    title: 'Public cloud',
    description:
      'Users from anywhere globally connect to the cloud to manage computing resources in the data center through an open interface.',
  },
  {
    icon: 'spriteIcon_37',
    title: 'Private cloud',
    description:
      'Infrastructure within one company, which cannot move to the public cloud, has its code. Access to the cloud is possible only within this company.',
  },
  {
    icon: 'spriteIcon_51',
    title: 'Community cloud',
    description:
      'It combines several organizations or communities that have created a single cloud to solve specific tasks. It can be deployed both in private and in a public cloud.',
  },
  {
    icon: 'spriteIcon_60',
    title: 'Hybrid cloud',
    description:
      'Three previous models are combined here. Most often, these are private and public clouds integrated through a VPN tunnel. This combination is used in different situations.',
  },
]

export default CloudAppDevModels
